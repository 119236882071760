import React, { useState } from 'react';
import { TextField, FormControlLabel, Checkbox, Typography, Grid, IconButton, InputAdornment } from '@material-ui/core';
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import AuthorizationClient from '../single-page/api/AuthorizationClient.js'
import LoadingButton from '../components/LoadingButton.js'

const useStyles = makeStyles((theme) => ({
  h1: {
    margin: '15px 30px',
    marginRight: 'auto'
  },
  h4: {
    margin: '5px 30px',
    marginRight: 'auto'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
    borderRadius: '31px',
    border: '1px solid #6600CB',
    padding: '32px 32px 20px 32px',
    backgroundColor: '#091250',
  },
  submit: {
    margin: theme.spacing(3, 0, 1),
  },
  submitSignUp: {
    margin: theme.spacing(0),
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  link: {
    color: '#D2B5FF',
    textDecoration: 'none'
  },
  link2: {
    color: '#fff',
    textDecoration: 'none'
  }
}));

export default function SignIn() {
  const classes = useStyles();
  const [login, setLogin] = useState("");
  const [loginError, setLoginError] = useState();
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  function sign_in(e) {
    e.preventDefault();
    document.activeElement.blur();

    setLoading(true);
    AuthorizationClient.signIn({
      login: login,
      password: password,
      bet_type: "esportesdasorte",
    })
      .then(function () {
        window.location.href = "/";
      })
      .catch(function (error) {
        if (error.response?.status == 401) {
          setLoginError(error.response.data?.error);
        } else {
          setLoginError();
        }
      })
      .then(function () {
        setLoading(false);
      });
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className={classes.paper}>
      <Typography className={classes.h1} component="h1" variant="h5">
        Login
      </Typography>
      <Typography className={classes.h4} component="h4">
        Entre com o login e senha recebidos
      </Typography>
      <form className={classes.form} onSubmit={sign_in} noValidate>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="Email / Username"
          value={login}
          error={login}
          helperText={loginError}
          onChange={(e) => setLogin(e.target.value)}
          autoComplete="email"
          autoFocus
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          value={password}
          error={passwordError}
          helperText={passwordError}
          onChange={(e) => setPassword(e.target.value)}
          label="Senha"
          type={showPassword ? 'text' : 'password'}
          autoComplete="current-password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={togglePasswordVisibility} edge="end">
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <LoadingButton
          type="submit"
          fullWidth
          loading={loading}
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          ENTRAR
        </LoadingButton>
        {/* Não será usado */}
        {/* <LoadingButton
          fullWidth
          loading={loading}
          variant="contained"
          color="primary"
          className={classes.submitSignUp}
        >
          <Link className={classes.link2} to="/public/sign_up">
            CADASTRE-SE
          </Link>
        </LoadingButton> */}
      </form>
      <Grid container style={{ display: 'flex', flexDirection: 'column' }}>
        <Grid style={{ padding: '20px 20px 10px 20px' }} item xs>
          <Link className={classes.link} to="/public/request_password_reset" variant="body2">
            Esqueceu sua senha?
          </Link>
        </Grid>
      </Grid>
    </div>
  );
}
