import { createMuiTheme } from '@material-ui/core/styles';
import backgroundSvg from 'images/grid.svg';

// Create a theme instance.
export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#120478',
    },
    secondary: {
      main: '#6600CB',
    },
    listOpened: {
      main: '#1EC6A8',
    },
    listSelected: '#fc842b',
    background: {
      list: '#173963',
      nested: '#63504c',
    },
  },
  typography: {
    fontFamily: 'Poppins'
  },
  shape: {
    borderRadius: 20,
  },
  spacing: 0,
  overrides: {
    MuiSelect: {
      root: {
        padding: '12px 24px 12px 8px !important',
        color: 'white !important',
        border: '0.5px solid white !important',
        borderRadius: '10px !important',
        '@media(max-width: 600px)': {
          padding: '10px 25px 10px 8px !important',
          borderRadius: '5px !important',
          fontSize: '0.8rem'
        }
      },
    },
    MuiButtonBase: {
      root: {
        color: '#fff !important'
      },
    },
    MuiTableHead: {
      root: {
        background: 'linear-gradient(90deg, rgba(18, 4, 120, 1) 0%, rgba(15, 24, 150, 1) 100%)'
      }
    },
    MuiTableCell: {
      root: {
        backgroundColor: 'transparent !important'
      },
      header: {
        backgroundColor: 'transparent !important'
      },
      body: {
        color: '#fff !important'
      }
    },
    MuiTableBody: {
      root: {
        backgroundColor: '#120478',
        color: '#fff',
      }
    },
    MuiDataGrid: {
      root: {
        backgroundColor: '#120478',
        color: '#fff',
      }
    },
    MuiCheckbox: {
      root: {
        color: '#f2f2f2',
      }
    },
    MuiSvgIcon: {
      root: {
        fill: '#f2f2f2'
      }
    },
    MuiMenuItem: {
      root: {
        background: "linear-gradient(90deg, rgba(18, 4, 120, 1) 0%, rgba(15, 24, 150, 1) 100%)",
        justifyContent: 'center',
        color: '#fff',
        '@media(max-width: 600px)': {
          minHeight: 'auto'
        },
      }
    },
    MuiTypography: {
      root: {
        color: '#fff',
      }
    },
    MuiInputBase: {
      root: {
        '& .Mui-disabled': {
          backgroundColor: '#f2f2f2',
        }
      },
    },
    MuiIconButton: {
      root: {
        '&:hover': { backgroundColor: '#58DF46' }
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: 14
      }
    },
    MuiCssBaseline: {
      '@global': {
        html: {},
        body: {
          fontSize: 16,
          fontFamily: 'Poppins',
          backgroundColor: '#120478',
          color: '#183B56'
        },
      },
    },
    MuiContainer: {
      maxWidthLg: {
        padding: 0,
        maxWidth: 1140,
        '@media(min-width: 1280px)': {
          maxWidth: 1140,
        },
      },
    },
    MuiButton: {
      root: {
        backgroundColor: '#2AB4C6',
        textTransform: 'none',
        '&:hover': {
          backgroundColor: '#2ab4c68f',
        },
      },
      containedPrimary: {
        background: 'transparent linear-gradient(90deg, #4568DC 0%, #0F1896 0%, #6600CB 100%) 0% 0% no-repeat padding-box',
        borderRadius: '5px',
        fontFamily: 'Poppins-Medium',
        fontSize: '20px',
        '@media(max-width: 600px)': {
          fontSize: '18px'
        }
      },
      contained: {
        color: 'white',
      },
      text: {
        color: 'white',
        fontWeight: 'bold',
      },
      label: {
        padding: '0px 10px',
      },
      outlined: {
        backgroundColor: 'transparent',
        border: '1px solid #2ab4c68f',
        color: '#2AB4C6',
        '&:hover': {
          color: 'white',
        },
      },
    },
    MuiInput: {
      underline: {
        '&::before': {
          border: 'none',
        },
        '&::after': {
          border: 'none',
        },
        '&:hover:not(.Mui-disabled):before': {
          border: 'none',
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 0,
        backgroundColor: '#120478',
        color: '#fff',
      },
      notchedOutline: {
        borderRadius: '5px',
        border: '1px solid #6600CB !important',
      },
      adornedEnd: {
        paddingRight: 0,
        '& .MuiIconButton-root': {
          padding: '15px',
          borderRadius: '5px',
          border: 'none',
        },
        '& .MuiIconButton-edgeEnd': {
          marginRight: '0px'
        }
      },
    },
    MuiAppBar: {
      colorPrimary: {
        color: '#6A707E',
        backgroundColor: '#120478',
        boxShadow: 'rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px',
      },
    },
    MuiList: {
      root: {
        paddingTop: '0px !important',
        paddingBottom: '0px !important',
      }
    },
    MuiListItem: {
      root: {
        // color: '#FFF !important'
      },
      button: {
        '&:hover': {
          backgroundColor: '#58DF46'
        }
      }
    },
    MuiPaper: {
      elevation8: {
        backgroundColor: 'transparent !important',
        boxShadow: 'none'
      },
      elevation2: {
        backgroundColor: 'transparent !important',
        boxShadow: 'none'
      },
    },
  },
  MuiListItemText: {
    root: {
      color: '#fff'
    }
  },
  MuiPaper: {
    outlined: {
      padding: 20,
    },
  },
  MuiCheckbox: {
    color: '#fc842b !important',
    fill: '#fc842b !important',
    colorSecondary: {
      color: '#fc842b !important',
      fill: '#fc842b !important',
      '&$checked': {
        color: '#fc842b !important',
      },
    },
    colorPrimary: {
      color: '#fc842b !important',
      fill: '#fc842b !important',
      '&$checked': {
        color: '#fc842b !important',
      },
    },
  },
  props: {
    MuiInputLabel: {
      style: { color: '#464A53' },
    },
    MuiFormControl: {
      fullWidth: true,
    },
  
    MuiTab: {
      disableRipple: true,
    },
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiButton: {
      disableRipple: true,
    },
    MuiCheckbox: {
      disableRipple: true,
    },
    MuiTextField: {
      variant: 'outlined',
      margin: 'normal',
      autoComplete: 'off',
      fullWidth: true,
      // defaultValue: '',
      InputLabelProps: {
        shrink: true,
        style: { color: '#58DF46' },
      },
    },
    MuiPaper: {
      elevation: 8,
    },
    MuiCard: {
      elevation: 8,
    },
    MuiOutlinedInput: {},
  },
});
